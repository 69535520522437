var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'name', {
                rules: [
                  { required: true, message: 'Name is required.' },
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]),expression:"[ 'name', {\n                rules: [\n                  { required: true, message: 'Name is required.' },\n                  { max: 64, message: 'Maximum 64 characters allowed.'}\n                  ]}]"}],attrs:{"type":"text","placeholder":"Publisher Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Email"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's e-mail address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'email', {
                         rules: [
                           { required: true, message: 'Email is not valid.', pattern: _vm.emailPattern },
                           { max: 64, message: 'Maximum 64 characters allowed.'}
                           ]}]),expression:"[ 'email', {\n                         rules: [\n                           { required: true, message: 'Email is not valid.', pattern: emailPattern },\n                           { max: 64, message: 'Maximum 64 characters allowed.'}\n                           ]}]"}],attrs:{"type":"email","placeholder":"Publisher Email Address"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Username"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's username. It is required for fetching statistics through stats API or CSV export.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'username', {
                         rules: [
                           { required: true, message: 'Username is required.' },
                           { pattern: _vm.usernameRegExp, message: 'Spaces not allowed in username.'},
                           { max: 32, message: 'Maximum 32 characters allowed.'}
                           ]}]),expression:"[ 'username', {\n                         rules: [\n                           { required: true, message: 'Username is required.' },\n                           { pattern: usernameRegExp, message: 'Spaces not allowed in username.'},\n                           { max: 32, message: 'Maximum 32 characters allowed.'}\n                           ]}]"}],attrs:{"type":"text","placeholder":"Publisher Username"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Country"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's country.")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country', {rules: [{ required: true, message: 'Country is required.' }]}]),expression:"['country', {rules: [{ required: true, message: 'Country is required.' }]}]"}],attrs:{"placeholder":"Select a country","showSearch":true,"filterOption":_vm.countryFilter,"optionFilterProp":"children"}},_vm._l((_vm.info.countries),function(obj){return _c('a-select-option',{key:obj.CC},[_c('img',{attrs:{"src":_vm.matchIcon(_vm.countries, obj.CC)}}),_vm._v(" "+_vm._s(obj.Country))])}),1)],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Description"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's description.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
                rules: [
                           { max: 512, message: 'Maximum 512 characters allowed.'}
                       ]
              }]),expression:"['description', {\n                rules: [\n                           { max: 512, message: 'Maximum 512 characters allowed.'}\n                       ]\n              }]"}],attrs:{"placeholder":"Publisher Description"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Website"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's website.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['website', {
                rules: [
                           { max: 64, message: 'Maximum 64 characters allowed.'}
                       ]
              }]),expression:"['website', {\n                rules: [\n                           { max: 64, message: 'Maximum 64 characters allowed.'}\n                       ]\n              }]"}],attrs:{"placeholder":"Publisher Website"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"City"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's city.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'city', { initialValue: null,
               rules: [
                           { max: 20, message: 'Maximum 20 characters allowed.'}
                       ]}]),expression:"[ 'city', { initialValue: null,\n               rules: [\n                           { max: 20, message: 'Maximum 20 characters allowed.'}\n                       ]}]"}],attrs:{"placeholder":"City"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Zip Code"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's zip code.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'zipCode', { initialValue: null,
              rules: [{ transform: function(value) { return Number(value)}, type: 'number' }] }]),expression:"[ 'zipCode', { initialValue: null,\n              rules: [{ transform: function(value) { return Number(value)}, type: 'number' }] }]"}],attrs:{"type":"number","placeholder":"Zip Code"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Address"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'address', { initialValue: null, rules: [
                           { max: 64, message: 'Maximum 64 characters allowed.'}
                       ]}]),expression:"[ 'address', { initialValue: null, rules: [\n                           { max: 64, message: 'Maximum 64 characters allowed.'}\n                       ]}]"}],attrs:{"placeholder":"Address"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Phone"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's phone number.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'phone', { initialValue: null,
               rules: [
                 { message: 'Invalid phone number.' },
                 { max: 20, message: 'Maximum 20 characters allowed.'}
                 ]}]),expression:"[ 'phone', { initialValue: null,\n               rules: [\n                 { message: 'Invalid phone number.' },\n                 { max: 20, message: 'Maximum 20 characters allowed.'}\n                 ]}]"}],attrs:{"placeholder":"Phone"},on:{"keydown":function($event){return _vm.phoneRegExp($event)}}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Skype"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Publisher's Skype username.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'skype', { initialValue: null,
               rules: [
                           { max: 32, message: 'Maximum 32 characters allowed.'}
                       ]}]),expression:"[ 'skype', { initialValue: null,\n               rules: [\n                           { max: 32, message: 'Maximum 32 characters allowed.'}\n                       ]}]"}],attrs:{"placeholder":"Skype"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Platform Domain"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("The canonical domain name of the advertising system of the publisher. RTB Zone of the publisher will try to inherit this value on creation.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['platformDomain', { rules: [{ max: 32, message: 'Maximum 32 characters allowed.'},
                        { pattern: _vm.domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com'}]}]),expression:"['platformDomain', { rules: [{ max: 32, message: 'Maximum 32 characters allowed.'},\n                        { pattern: domainRegExp, message: '\\'www.\\', \\'http://\\' and \\'https://\\' are not allowed. Please input pure top-level domain, for example: google.com'}]}]"}],attrs:{"type":"text","placeholder":"Publisher Platform Domain"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"sellers.json Domain"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("The canonical domain where advertising system of the publisher hosts its sellers.json file. Usually it is the same domain as Platform Domain. RTB Zone of the publisher will try to inherit this value on creation.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sellersJsonDomain', { rules: [{ max: 32, message: 'Maximum 32 characters allowed.'},
                        { pattern: _vm.domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com'}]}]),expression:"['sellersJsonDomain', { rules: [{ max: 32, message: 'Maximum 32 characters allowed.'},\n                        { pattern: domainRegExp, message: '\\'www.\\', \\'http://\\' and \\'https://\\' are not allowed. Please input pure top-level domain, for example: google.com'}]}]"}],attrs:{"type":"text","placeholder":"sellers.json Domain"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Seller Type"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Type of the Publisher's seller account that will be displayed in sellers.json.")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sellerType', { initialValue: '2' }]),expression:"['sellerType', { initialValue: '2' }]"}],attrs:{"placeholder":"Seller Type","filterOption":true,"optionFilterProp":"children"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("Publisher")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("Intermediary")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("Both")])],1)],2)],1),_c('a-divider',{staticClass:"mt-3"}),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper"},[_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit"}},[_vm._v("Create Publisher")]),_c('button',{staticClass:"btn btn-light px-5 ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'publishers' })}}},[_vm._v("Cancel ")])])])],1)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Create Publisher")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Create new publisher")])])}]

export { render, staticRenderFns }